<template>
  <div class="order">
    <div class="container">
      <!-- 面包屑导航 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/allCourses' }"
          >全部课程</el-breadcrumb-item
        >
        <el-breadcrumb-item
          class="jump_nav"
          v-for="item in navList"
          :key="item.categoryId"
          @click.native="jump(item)"
          >{{ item.name }}</el-breadcrumb-item
        >
        <el-breadcrumb-item
          :to="{ path: '/courseDetail', query: $route.query }"
          >{{ courseInfo.title }}</el-breadcrumb-item
        >
        <el-breadcrumb-item>确认订单</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="tips">
        <i class="iconfont">&#xe615;</i>
        <p>
          请在平台进行线上交易。与课程方私下交易造成的任何损失，万慧教育不承担责任。如有讲师提出私下交易，请联系我们投诉。
        </p>
      </div>
      <div class="order_info">
        <p class="title">确认订单信息</p>
        <div class="info">
          <div class="cover">
            <img :src="courseInfo.img" alt="" />
          </div>
          <div class="synopsis">
            <p class="course_title">{{ courseInfo.title }}</p>
            <div class="teachers">
              讲师：<span
                v-for="(teacher, idx) in courseInfo.teacherList"
                :key="teacher.teacherId"
                >{{ teacher.name
                }}<span v-show="idx + 1 < courseInfo.teacherList.length"
                  >、</span
                ></span
              >
              <span class="already_study"
                >已学习{{ courseInfo.playCount }}人</span
              >
            </div>
            <div class="price">
              <p v-show="courseInfo.price > 0">
                <span style="font-size: 18px">￥</span>{{ courseInfo.price }}
              </p>
              <p v-show="courseInfo.price == 0">免费</p>
            </div>
          </div>
        </div>
        <div class="pay_type">
          <p class="type_title">
            支付方式
            <span class="warning"
              >购买后不支持退款、转让、请确认课程信息后再进行购买</span
            >
          </p>
          <div class="choose_area">
            <div
              class="one_chioce"
              :class="{ active: payChannel == item.val }"
              v-for="item in payChannelList"
              :key="item.val"
              @click="payChannel = item.val"
            >
              <img class="icon" :src="item.icon" /><span class="label">{{
                item.label
              }}</span>
              <span class="point"></span>
            </div>
          </div>
        </div>
        <div class="pay_confirm">
          <p class="agreement">
            提交订单则表示您同意<span @click="jumpAgreement(3)"
              >《万慧教育用户服务协议》</span
            >
          </p>
          <div class="pay_price">
            实付：<span
              ><span style="font-size: 20px">￥</span
              >{{ courseInfo.price }}</span
            >
          </div>
        </div>
        <div style="text-align: right; margin-top: 20px">
          <button class="confirm_btn" @click="goPay">立即支付</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCourseDetail } from "@/api/curriculum";

export default {
  name: "order",
  created() {
    this.getCourse();
  },
  computed: {
    categorys() {
      return this.$store.state.classificationOrgin;
    },
  },
  methods: {
    // 课程信息
    getCourse() {
      getCourseDetail(this.$route.query.courseId).then((res) => {
        if (res.code == 200) {
          this.courseInfo = res.data;
          this.findCategory(res.data.categoryIds.split(",").slice(1, -1));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    findCategory(arr) {
      this.categorys.forEach((item) => {
        arr.includes(String(item.categoryId)) && this.navList.push(item);
      });
    },
    // 面包屑跳转
    jump(ctx) {
      if (ctx.level == 3) {
        this.$router.push({
          path: "/allCourses",
          query: {
            mt: this.navList[0].categoryId,
            st: ctx.parentId,
            tt: ctx.categoryId,
          },
        });
      } else if (ctx.level == 2) {
        this.$router.push({
          path: "/allCourses",
          query: {
            mt: ctx.parentId,
            st: ctx.categoryId,
          },
        });
      } else {
        this.$router.push({
          path: "/allCourses",
          query: {
            mt: ctx.categoryId,
          },
        });
      }
    },
    jumpAgreement(option) {
      let newUrl = this.$router.resolve({
        path: "/helpCenter",
        query: { option },
      });
      window.open(newUrl.href, "_blank");
    },
    // 立即支付
    goPay() {
      if (!this.payChannel) {
        this.$message.warning("请先选择支付方式！");
        return;
      }
      this.$router.push({
        path: "/pay",
        query: {
          courseId: this.$route.query.courseId,
          payChannel: this.payChannel,
          payType: this.payType,
        },
      });
    },
  },
  data() {
    return {
      courseInfo: {},
      navList: [],
      payChannel: "",
      payType: "NATIVE",
      payChannelList: [
        {
          label: "支付宝支付",
          icon: require("@/assets/img/zhifubao.png"),
          val: "alipay",
        },
        {
          label: "微信支付",
          icon: require("@/assets/img/weixin.png"),
          val: "wxpay",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.order {
  min-height: calc(100vh - 66px - 200px);
  background-color: #f3f3f3;
  ::v-deep.el-breadcrumb {
    padding: 22px 0;
    .jump_nav {
      .el-breadcrumb__inner {
        font-weight: 700;
        transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        color: #303133;
        cursor: pointer;
        &:hover {
          color: #409eff;
        }
      }
    }
  }
}
.tips {
  padding: 0 22px;
  width: 100%;
  height: 54px;
  line-height: 54px;
  font-size: 14px;
  text-align: left;
  color: #ff8a22;
  background: #fff5ed;
  border-radius: 6px;
  .iconfont {
    margin-right: 7px;
  }
  p {
    display: inline-block;
  }
}
.order_info {
  padding: 24px 21px 34px 21px;
  margin-bottom: 50px;
  text-align: left;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  .title {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .info {
    overflow: hidden;
    .cover {
      float: left;
      margin-right: 23px;
      width: 362px;
      height: 224px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .synopsis {
      float: left;
      .course_title {
        font-size: 22px;
        line-height: 40px;
      }
      .teachers {
        font-size: 14px;
        color: #999;
        .already_study {
          margin-left: 18px;
        }
      }
      .price {
        margin-top: 18px;
        font-size: 24px;
        color: #ff8a22;
      }
    }
  }
  .pay_type {
    margin-top: 20px;
    .type_title {
      line-height: 3;
      font-size: 18px;
      .warning {
        font-size: 14px;
        color: #999;
      }
    }
    .choose_area {
      padding-bottom: 33px;
      border-bottom: solid 1px #eee;
      .one_chioce {
        position: relative;
        display: inline-block;
        margin-right: 27px;
        width: 362px;
        height: 99px;
        line-height: 99px;
        background: #ffffff;
        border: 1px solid #e8e8e8;
        border-radius: 2px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          background: #f2f8ff;
        }
        &.active {
          border-color: #0d79ff;
          background: #f2f8ff;
          .point {
            border-color: #0d79ff;
            &::before {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              content: "";
              width: 10px;
              height: 10px;
              background: #0d79ff;
              border-radius: 50%;
            }
          }
        }
        .icon {
          margin-left: 38px;
          margin-right: 14px;
        }
        .label {
          font-size: 18px;
        }
        .point {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 27px;
          // display: inline-block;
          width: 18px;
          height: 18px;
          background: #ffffff;
          border: 1px solid #e3e3e3;
          border-radius: 50%;
        }
      }
    }
  }
  .pay_confirm {
    margin-top: 25px;
    overflow: hidden;
    .agreement {
      float: left;
      padding-top: 5px;
      font-size: 14px;
      span {
        color: #0d79ff;
        cursor: pointer;
      }
    }
    .pay_price {
      float: right;
      span {
        display: inline-block;
        color: #ff8a22;
        font-size: 24px;
      }
    }
  }
  .confirm_btn {
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    background: #ff8a22;
    border-radius: 20px;
  }
}
</style>